<i18n>
[
    "global__close",
]
</i18n>

<template>
    <Transition name="fade">
        <div
            v-if="activeNotification && shouldShow"
            class="c-sticky-notification__outer o-row"
        >
            <div
                class="c-sticky-notification o-extra-small--12 o-extra-large--10 o-extra-large--offset-1"
                :style="notificationStyles"
            >
                <div class="c-sticky-notification__slot-contents">
                    <Component
                        :is="activeNotification.component"
                        v-if="activeNotification.component"
                        class="c-braze-sticky-notification__component"
                        @click="handleNotificationClick"
                    />
                    <StickyNotificationLayout
                        v-else
                        :illustrationSrc="activeNotification.illustration"
                        :title="activeNotification.title"
                        :subtitle="activeNotification.subtitle"
                        :cta="activeNotification.cta"
                        :destination="activeNotification.destination"
                        :subtitleHasMarkdown="subtitleHasMarkdown"
                        @click="handleNotificationClick"
                        @cta-click="handleNotificationCtaClick"
                    />
                </div>
                <div
                    v-tab-focus.onspace="dismissNotification"
                    :aria-label="$t('global__close')"
                    class="c-sticky-notification__close-btn"
                >
                    <BaseIcon icon="global--close" size="12px" color="primary" />
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { nuColorWhite } from '~coreModules/core/js/temp-colors';

import {
    NOTIFICATION_CLICKED,
    NOTIFICATION_DISMISSED,
    NOTIFICATION_VIEWED,
} from '~coreModules/core/js/global-event-constants';
import { NOTIFICATION_DISMISS } from '~coreModules/analytics/js/analytics-constants';

import StickyNotificationLayout from '~coreModules/notifications/components/StickyNotificationLayout.vue';

export default {
    name: 'StickyNotification',
    components: {
        StickyNotificationLayout,
    },
    props: {
        activeNotification: {
            type: Object,
            default: null,
        },
        shouldShow: {
            type: Boolean,
            default: true,
        },
        subtitleHasMarkdown: {
            type: Boolean,
            default: false,
        },
        ctaClickMethod: {
            type: Function,
            default: null,
        },
    },
    emits: [
        'notification-shown',
        'notification-clicked',
        'notification-dismissed',
    ],
    computed: {
        notificationStyles() {
            return {
                backgroundColor: this.activeNotification.bkgColor || nuColorWhite,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.trackShownNotification();
        });
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        trackShownNotification() {
            this.$emit('notification-shown');
            this.trackNotificationEvent(NOTIFICATION_VIEWED);
        },
        handleNotificationClick(path) {
            this.trackNotificationEvent(NOTIFICATION_CLICKED);
            this.$emit('notification-clicked');

            if (path && !this.ctaClickMethod) {
                if (typeof path === 'object') {
                    this.$router.push(path);
                } else {
                    this.$router.push({ path });
                }
            }
        },
        handleNotificationCtaClick() {
            this.trackNotificationEvent(NOTIFICATION_CLICKED);
            if (this.ctaClickMethod) {
                this.ctaClickMethod();
            }
        },
        dismissNotification() {
            this.$emit('notification-dismissed');
            this.trackNotificationEvent(NOTIFICATION_DISMISSED);
        },
        trackNotificationEvent(type) {
            const isClickedOrDismissed = type === NOTIFICATION_DISMISSED || type === NOTIFICATION_CLICKED;
            const destination = type === NOTIFICATION_DISMISSED ? NOTIFICATION_DISMISS :
                this.activeNotification.destination;

            this.trackGlobalEvent({
                type,
                data: {
                    notificationType: this.activeNotification.notificationType,
                    notificationSlug: this.activeNotification.slug,
                    ...(isClickedOrDismissed && {
                        destination,
                    }),
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .c-sticky-notification {
        position: relative;
        min-height: 48px;
        display: flex;
        box-shadow: $sticky-notification-shadow;
        margin: 0 $nu-spacer-1;

        @include breakpoint(medium) {
            margin: 0 $nu-spacer-3;
        }

        &__outer {
            position: fixed;
            z-index: map-get($zindex, braze-sticky-notification);
            bottom: $nu-spacer-3;
            left: 0;
            right: 0;

            @include breakpoint(medium) {
                bottom: $nu-spacer-4;
            }
        }

        &__slot-contents {
            flex-grow: 1;
            padding: $nu-spacer-2 0 $nu-spacer-2 $nu-spacer-2;
            cursor: pointer;

            @include breakpoint(medium) {
                padding: $nu-spacer-2 0 $nu-spacer-2 $nu-spacer-3;
            }
        }

        &__close-btn {
            width: 52px;
            height: 52px;
            cursor: pointer;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
        }
    }
</style>
